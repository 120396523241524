@import '~@angular/material/theming';

$light-theme-color-config: mat-get-color-config($light-theme);
$dark-theme-color-config: mat-get-color-config($dark-theme);

:root {
  --accent-color: #{mat-color(map-get($map: $light-theme-color-config, $key: accent))};
  --success-color: #4caf50;
  --app-bar-background-color: #{map.get($light-theme-color-config, background, app-bar)};
  --background-color: #{map.get($light-theme-color-config, background, background)};
  --dark-surface: #{map.get($dark-theme-color-config, background, card)};
  --dashboard-sidenav-width: 230px;
  --dashboard-toolbar-height: 64px;
  --dividers-color: #{map.get($light-theme-color-config, foreground, dividers)};
  --light-surface: #{map.get($light-theme-color-config, background, card)};
  --primary-color: #{mat-color(map-get($map: $light-theme-color-config, $key: primary))};
  --primary-text-color: #{map.get($light-theme-color-config, foreground, text)};
  --secondary-text-color: #{map.get($light-theme-color-config, foreground, secondary-text)};
  --default-contrast: #{map.get($light-theme-color-config, primary, default-contrast)};
  --lighter-contrast: #{map.get($light-theme-color-config, primary, lighter-contrast)};
  --darker-contrast: #{map.get($light-theme-color-config, primary, darker-contrast)};
  --surface-a: #{map.get($light-theme-color-config, background, card)};
  --tec-software-orange-100: #{mat-color($tec-software-orange, 100)};
  --tec-software-orange-200: #{mat-color($tec-software-orange, 200)};
  --tec-software-orange-300: #{mat-color($tec-software-orange, 300)};
  --tec-software-orange-400: #{mat-color($tec-software-orange, 400)};
  --tec-software-orange-50: #{mat-color($tec-software-orange, 50)};
  --tec-software-orange-500: #{mat-color($tec-software-orange, 500)};
  --tec-software-orange-600: #{mat-color($tec-software-orange, 600)};
  --tec-software-orange-700: #{mat-color($tec-software-orange, 700)};
  --tec-software-orange-800: #{mat-color($tec-software-orange, 800)};
  --tec-software-orange-900: #{mat-color($tec-software-orange, 900)};
  --tec-software-orange-A100: #{mat-color($tec-software-orange, A100)};
  --tec-software-orange-A200: #{mat-color($tec-software-orange, A200)};
  --tec-software-orange-A300: #{mat-color($tec-software-orange, A300)};
  --tec-software-orange-A400: #{mat-color($tec-software-orange, A400)};
  --tec-software-orange-A50: #{mat-color($tec-software-orange, A50)};
  --tec-software-orange-A500: #{mat-color($tec-software-orange, A500)};
  --tec-software-orange-A600: #{mat-color($tec-software-orange, A600)};
  --tec-software-orange-A700: #{mat-color($tec-software-orange, A700)};
  --tec-software-orange-A800: #{mat-color($tec-software-orange, A800)};
  --tec-software-orange-A900: #{mat-color($tec-software-orange, A900)};
  --tec-software-purple-100: #{mat-color($tec-software-purple, 100)};
  --tec-software-purple-200: #{mat-color($tec-software-purple, 200)};
  --tec-software-purple-300: #{mat-color($tec-software-purple, 300)};
  --tec-software-purple-400: #{mat-color($tec-software-purple, 400)};
  --tec-software-purple-50: #{mat-color($tec-software-purple, 50)};
  --tec-software-purple-500: #{mat-color($tec-software-purple, 500)};
  --tec-software-purple-600: #{mat-color($tec-software-purple, 600)};
  --tec-software-purple-700: #{mat-color($tec-software-purple, 700)};
  --tec-software-purple-800: #{mat-color($tec-software-purple, 800)};
  --tec-software-purple-900: #{mat-color($tec-software-purple, 900)};
  --tec-software-purple-A100: #{mat-color($tec-software-purple, A100)};
  --tec-software-purple-A200: #{mat-color($tec-software-purple, A200)};
  --tec-software-purple-A300: #{mat-color($tec-software-purple, A300)};
  --tec-software-purple-A400: #{mat-color($tec-software-purple, A400)};
  --tec-software-purple-A50: #{mat-color($tec-software-purple, A50)};
  --tec-software-purple-A500: #{mat-color($tec-software-purple, A500)};
  --tec-software-purple-A600: #{mat-color($tec-software-purple, A600)};
  --tec-software-purple-A700: #{mat-color($tec-software-purple, A700)};
  --tec-software-purple-A800: #{mat-color($tec-software-purple, A800)};
  --tec-software-purple-A900: #{mat-color($tec-software-purple, A900)};
  --warn-color: #{mat-color(map-get($map: $light-theme-color-config, $key: warn))};
  --swiper-theme-color: #{var(--primary-color)};
}


.dark-mode {
  --accent-color: #{mat-color(map-get($map: $dark-theme-color-config, $key: accent))};
  --app-bar-background-color: #{map.get($dark-theme-color-config, background, app-bar)};
  --background-color: #{map.get($dark-theme-color-config, background, background)};
  --dividers-color: #{map.get($dark-theme-color-config, foreground, dividers)};
  --primary-color: #{mat-color(map-get($map: $dark-theme-color-config, $key: primary))};
  --primary-text-color: #{map.get($dark-theme-color-config, foreground, text)};
  --secondary-text-color: #{map.get($dark-theme-color-config, foreground, secondary-text)};
  --default-contrast: #{map.get($dark-theme-color-config, primary, default-contrast)};
  --lighter-contrast: #{map.get($dark-theme-color-config, primary, lighter-contrast)};
  --darker-contrast: #{map.get($dark-theme-color-config, primary, darker-contrast)};
  --surface-a: #{map.get($dark-theme-color-config, background, card)};
  --warn-color: #{mat-color(map-get($map: $dark-theme-color-config, $key: warn))};
}
