
@use 'sass:map';
@use '@angular/material' as mat;

.excel-button {
  color: map.get(mat.$green-palette, contrast, 600);
  background-color: mat-color(mat.$green-palette, 600) !important;
}

.dark-mode {
  .excel-button {
    color: map.get(mat.$green-palette, contrast, 300);
    background-color: mat-color(mat.$green-palette, 300) !important;
  }
}
