@use 'sass:map';

@import '~@angular/material/theming';

// Font Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Typography
// @import './scss/typo';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import 'swiper/swiper-bundle.min.css';

// Theme
@import './scss/theme';

// Animations
@import './scss/animations/spin';

// Custom
@import './scss/dialog';
@import './scss/swal';
@import './scss/overlay';
@import './scss/scrollbar';
@import './scss/table';
@import './scss/form-field';
@import './scss/buttons';

// Common
@import './scss/common';

@import './scss/variables';

* {
  margin: 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.si-shadow-wrapper-top,
.si-content-wrapper {
  margin-top: 72px !important;
  border-radius: 0.5rem !important;
}

.si-content-wrapper {
  padding: 16px !important;
}

.si-content {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.active > .page-link,
.page-link.active {
  background-color: #383838 !important;
  border-color: #383838 !important;
}

.mat-dialog-content {
  overflow: inherit !important;
}

@media (min-width: 1024px) {
  .control-dialog-panel-class {
    min-width: 80vw;
    .mat-dialog-content {
      // min-height: 50vw;
    }
  }
}
