@use 'sass:map';
@use '@angular/material' as mat;
@import '~@angular/material/theming';

$theme-typography: mat.define-typography-config(
  $font-family: 'Poppins',
  $body-1: mat.define-typography-level(1rem, 1.1rem, 400),
  $caption:
    mat.define-typography-level(
      $font-size: 1rem,
      $font-weight: 500,
      $letter-spacing: -0.05em,
    ),
  $headline:
    mat.define-typography-level(
      $font-size: 2rem,
      $font-weight: 500,
      $letter-spacing: -0.05em,
    ),
  $title:
    mat.define-typography-level(
      $font-size: 1.5rem,
      $font-weight: 500,
      $letter-spacing: -0.05em,
    ),
  $subheading-2:
    mat.define-typography-level(
      $font-size: 1rem,
      $font-weight: 500,
      $letter-spacing: -0.05em,
    ),
);

@include mat.core($theme-typography);

$tec-software-purple: (
  50: #f2e3f3,
  100: #dfbae1,
  200: #cb8ccf,
  300: #b65dbb,
  400: #a639ad,
  500: #960a9f,
  600: #89089a,
  700: #760393,
  800: #66008b,
  /* Primary */ 900: #47007e,
  A50: #fbe4f2,
  A100: #f5bcde,
  A200: #f190c8,
  A300: #f061b0,
  A400: #f0399c,
  A500: #f20087,
  A600: #e00082,
  A700: #c8007b,
  A800: #b20075,
  /* Primary */ A900: #8b006b,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    // ... continues to 900
  ),
);

$tec-software-orange: (
  50: #fff8e1,
  100: #ffecb3,
  200: #ffe183,
  300: #ffd650,
  400: #ffcb29,
  500: #ffc20a,
  600: #ffb402,
  700: #ffa101,
  800: #ff9100,
  /* Primary */ 900: #ff7100,
  A50: #fcffe4,
  A100: #f7feba,
  A200: #f1fc87,
  A300: #eaf94c,
  A400: #eeff00,
  A500: #e8fb00,
  A600: #f0ea00,
  A700: #f7cf00,
  A800: #fdb400,
  A900: #ff8500,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    // ... continues to 900
  ),
);

$light-primary-palette: mat.define-palette(mat.$gray-palette, 900);
$light-accent-palette: mat.define-palette(mat.$gray-palette, 700);

$dark-primary-palette: mat.define-palette(mat.$gray-palette, 400);
$dark-accent-palette: mat.define-palette(mat.$gray-palette, 200);
$dark-warn-palette: mat.define-palette(mat.$red-palette, 300);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: $light-primary-palette,
      accent: $light-accent-palette,
    ),
    typography: $theme-typography,
    density: 0,
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $dark-primary-palette,
      accent: $dark-accent-palette,
      warn: $dark-warn-palette,
    ),
    typography: $theme-typography,
    density: 0,
  )
);

@include mat.all-component-themes($light-theme);

.dark-mode {
  $severity-is-dark-theme: true;
  @include mat.all-component-colors($dark-theme);
  // @import '~mat-snackbar-severity/mat-snackbar-severity-outlined';
}

/* https://www.npmjs.com/package/mat-snackbar-severity */

/* Make sure you are importing the global mixin of material: */
// @include angular-material-theme($light-theme);

/* or in the case of using another theme then specify the following mixins: */
// @include mat-snack-bar-theme($light-theme);
// @include mat-button-theme($light-theme);

/* design filled */
// @import '~mat-snackbar-severity/mat-snackbar-severity-filled';

/* design light outlined */
// @import '~mat-snackbar-severity/mat-snackbar-severity-outlined';
