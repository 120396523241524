.mat-form-field.mat-form-field-appearance-fill {
  line-height: 1.135;
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      padding: .65em .75em 0 .75em !important;
      .mat-form-field-infix {
        padding: 0.25em 0 0.65em 0 !important;
      }
    }
  }
}

.mat-form-field.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: .85em 0 1em 0 !important;
    border-top: 0.69375em solid transparent;
  }
}
